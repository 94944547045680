.accordion summary {
  display: flex;
  position: relative;
  line-height: 1;
  padding: 1.5rem 0;
}

.accordion .summary__title {
  display: flex;
  flex: 1;
}

.accordion .summary__title + .icon-caret {
  height: calc(var(--font-heading-scale) * 0.6rem);
}

.accordion + .accordion {
  margin-top: 0;
  border-top: none;
}

.accordion {
  margin-top: 2.5rem;
  margin-bottom: 0;
  border-top: 0.1rem solid rgba(var(--color-foreground), 0.08);
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
}

.accordion__title {
  display: inline-block;
  max-width: calc(100% - 6rem);
  min-height: 1.6rem;
  margin: 0;
  word-break: break-word;
}

.accordion .icon-accordion {
  align-self: center;
  fill: rgb(var(--color-foreground));
  height: calc(var(--font-heading-scale) * 2rem);
  margin-right: calc(var(--font-heading-scale) * 1rem);
  width: calc(var(--font-heading-scale) * 2rem);
}

.accordion details[open] > summary .icon-caret {
  transform: rotate(180deg);
}

.accordion__content {
  margin-bottom: 1.5rem;
  word-break: break-word;
  overflow-x: auto;
  padding: 0 0.6rem;
}

.accordion__content img {
  max-width: 100%;
}
